<template>
  <div class="cms-media-video-picker">
    <div class="cms-media-video-picker-form">
      <input
        class="ui-native"
        type="text"
        :value="value"
        @input="$emit('input', $event.target.value)"
        placeholder="URL (YouTube o Vimeo)"
      />
    </div>

    <cms-media-video :url="value"></cms-media-video>
  </div>
</template>

<script>
import CmsMediaVideo from '@/modules/cms/components/Media/Video/Video.vue';

export default {
  name: 'cms-media-video-picker',
  components: { CmsMediaVideo },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style lang="scss">
.cms-media-video-picker {
  .cms-media-video-picker-form {
    input {
      width: 100%;
      border-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}
</style>